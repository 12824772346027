import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Address from '../components/Address';
import Hero from '../components/Hero';
import Story from '../components/Story';
import BottomBar from '../components/BottomBar';
import BanksGrid from '../components/BanksGrid';
import Testimonials from '../components/Testimonials';
import Partnership from '../components/Partnership';
import 'normalize.css';
import Layout from '../components/Layout';

const AboutUs = ({ data }) => {
  const bannerImg = data.ogImg.ogimage[0].file.url;
  return (
    <>
      <SEO
        title={data.contentfulAboutUs.title}
        description={data.contentfulAboutUs.subtitle}
        bannerImage={bannerImg}
        pathname='about'
      />
      <Layout>
        <Helmet>
          <link
            rel='stylesheet'
            href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
          />
        </Helmet>
        <Hero
          bgImage={data.contentfulAboutUs.heroImage.file.url}
          isAboutUs={true}
          isFull='is-fullheight-with-navbar'
          title={data.contentfulAboutUs.title}
          subtitle={
            data.contentfulAboutUs.subtitle.length < 1
              ? ' '
              : data.contentfulAboutUs.subtitle
          }
          aboutUsClass='AboutUs'
          aboutUsData={data.contentfulAboutUs}
        />
        <BanksGrid BanksGrid={data.contentfulHomepage.banksGrid} />
        <Story
          heading={data.contentfulAboutUs.heading}
          subHeading={data.contentfulAboutUs.subHeading}
          story={data.contentfulAboutUs.ourStory}
          breaker={data.allContentfulAsset.nodes[0].file.url}
        />
        <br />
        <br />
        <Testimonials />
        {/* <CaseStudy /> */}
        <Partnership
          heading={data.contentfulPartners.microsoftTitle}
          Partnership={data.contentfulPartners.partnerships}
        />
        <div id='address'>
          <Address />
        </div>
        <BottomBar />
      </Layout>
    </>
  );
};

AboutUs.propTypes = {
  data: PropTypes.shape({
    allContentfulAsset: PropTypes.shape({
      nodes: PropTypes.any,
    }),
    contentfulAboutUs: PropTypes.shape({
      heading: PropTypes.any,
      heroImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      ourStory: PropTypes.shape({
        ourStory: PropTypes.any,
      }),
      subHeading: PropTypes.any,
    }),
    contentfulAsset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.any,
      }),
    }),
    contentfulHomepage: PropTypes.shape({
      banksGrid: PropTypes.any,
    }),
    contentfulNavbar: PropTypes.shape({
      about: PropTypes.any,
      products: PropTypes.any,
    }),
    contentfulPartners: PropTypes.shape({
      microsoftTitle: PropTypes.any,
      partnerships: PropTypes.any,
    }),
  }),
};
export const query = graphql`
  {
    ogImg: contentfulOpenGraph {
      ogimage {
        file {
          url
          fileName
        }
      }
    }
    contentfulPartners {
      microsoftTitle
      partnershipTitle
      clientLogos {
        file {
          url
        }
      }
      partnerships {
        file {
          url
          fileName
        }
      }
    }
    contentfulAboutUs {
      title
      subtitle
      heroImage {
        file {
          url
        }
      }
      heading
      subHeading
      ourStory {
        ourStory
        childMarkdownRemark {
          html
        }
      }
      row1
      row2
      row3
      row4
      row5
      row6
    }
    contentfulHomepage(children: {}) {
      banksGrid {
        file {
          url
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "breaker" } }) {
      nodes {
        file {
          url
        }
      }
    }
  }
`;
export default AboutUs;
