import React from 'react';

import '../styles/global.scss';

const Story = ({ heading, story, breaker }) => {
  return (
    <section className='section sec-story' style={{ paddingBottom: '10px' }}>
      <div className='columns '>
        <div className='column is-centered'>
          <span className='storyHeading'>
            <img
              src={breaker}
              className='is-hidden-mobile'
              alt='breaker'
              style={{ marginLeft: '20px' }}
            />
            <br />
            <i className='heading-story-vision'>{heading}</i>
          </span>
        </div>
      </div>
      <div
        className='columns is-mobile'
        style={{ marginBottom: '30px', color: '#8b959e' }}
      >
        <div
          className='column is-full'
          style={{ margin: '1vw', marginBottom: '20px' }}
        >
          <div
            style={{
              paddingTop: '1rem',
              fontFamily: 'Muli',
            }}
            className='AboutC'
            dangerouslySetInnerHTML={{
              __html: story.childMarkdownRemark.html,
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default Story;
