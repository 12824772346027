import React, { useState } from 'react';
import divider from '../images/divider-colored.svg';
import '../styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressCard from './AddressCard';
import { useStaticQuery, graphql } from 'gatsby';

const Address = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulOfficeLocations(sort: { fields: [priority], order: ASC }) {
          nodes {
            address {
              address
            }
            country
            priority
            image {
              file {
                url
              }
            }
          }
        }
      }
    `
  );

  return (
    <section
      style={{
        marginBottom: '65px',
        marginTop: '65px',
      }}
    >
      <div className='column is-full'>
        <center>
          <img src={divider} alt='divider' />
          <p className='SectionHeading' style={{ padding: 0, marginBottom: 1 }}>
            Office Locations
          </p>
        </center>
      </div>
      <div className='address-component'>
        {Object.keys(data.allContentfulOfficeLocations.nodes).map(i => {
          return (
            <>
              <AddressCard
                country={data.allContentfulOfficeLocations.nodes[i].country}
                img={data.allContentfulOfficeLocations.nodes[i].image.file.url}
                address={
                  data.allContentfulOfficeLocations.nodes[i].address.address
                }
              />
            </>
          );
        })}
      </div>
    </section>
  );
};

export default Address;
