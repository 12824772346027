import React from 'react';
import '../styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddressCard = ({ country, img, address }) => {
  return (
    <div className='address-card'>
      <div className='address-img'>
        <img
          src={img}
          style={{ height: '300px', width: '458px', objectFit: 'cover' }}
        />
      </div>
      <div className='address-country'>{country}</div>
      {address !== 'NA' ? <div className='address-text'>{address}</div> : null}
    </div>
  );
};

export default AddressCard;
